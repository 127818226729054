jQuery(document).ready(function($) {
	$('.testimonial-video-slider-wrapper .testimonial-video-slider-slider-wrapper:not(.one-slide)').slick({
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		autoplay: false,
		autoplaySpeed: 7000,
	});
}); /* end of as page load scripts */